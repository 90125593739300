import './App.css';
import Chat from './Chat';
//import AboutJohnBaldoni from './components/AboutJohnBaldoni';
//import Leadership from './components/Leadership';
// Import assets
import brandLogo from './influencerkit-logo.png';
//import jb from './jb.png'

function App() {
  return (
    <div className="App">
      {/* Brand Logo */}
      <div className="brand-logo">
        <a href="https://influencerkit.ai">
          <img src={brandLogo} className="brandLogo" alt='brandLogo' />
        </a>
      </div>

      {/* Welcome Message */}
      <div className="text-container">
        <div className="top-half">Powers</div>
        <div className="bottom-half">Feyzi Fatehi AI ChatBot</div>
      </div>
      <div className="image-container">
        <div className="receive">
        <p> Receive Feyzi Fatehi's answers on Corent Technology, SaaS (Software as a Service), “Democratizing SaaS” (his book published by Forbes) and entrepreneurial leadership related topics about which he has written and spoken.
        </p>
      </div>
      </div>
    
      {/* Chat Widget */}
      <Chat className="chat-container" projectId="65523ba7d549fb000708136c" />





    </div>
  );

}

export default App;